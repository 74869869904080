$cwhite: #FFFFFF;
$cblack: #000000;
$cbg: #f7fdfd;
$cprimary: #2daae1;
$ctext: #717171;
$cborder: #c9c9c9;

@import "./modules/helpers";

html { font-size: 62.5%; height: 100%;}

body {
    background-color: $cbg;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    position: relative;
    margin: 0;
    padding-bottom: 190px;
    min-height: 100%;
    color: $ctext;
    font-size: 1.6rem;
}

a {
    color: $cprimary;
    &:hover {
        color: $cprimary;
        opacity: 0.7;
    }
}

p {
    font-size: 1.6rem;
    font-weight: 400;
}

ul {
    padding: 0 0 0 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #f8f8f8;
}
.custom-checkbox .custom-control-input ~ .custom-control-label {
    font-size: 1.4rem;
}
.custom-control-label {
    &::before {
        width: 15px;
        height: 15px;
        left: 0;
        left: -20px;
    }
    &::after {
        left: -20px;
        width: 15px;
        height: 15px;
    }
    span {
        top: 0;
    }
}
.custom-control-alternative .custom-control-label::before {
    box-shadow: none;
    border: 1px solid $cborder;
}
.custom-control {
    padding-left: 20px;
}
.select2-container .select2-results > .select2-results__options {
    border: 0;
}
.form-group .text-muted {
    margin-left: 20px;
    color: #b6b6b6;
    font-size: 1rem;
}

.videoContainer {
    position: relative;
    padding-bottom: 56.25%; /* ratio 16/9 */
    height: 0; overflow: hidden;
    clear: both;
     iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.btn-primary {
    background-color: $cprimary;
    text-transform: uppercase;
    color: $cwhite;
    font-size: 1.8rem;
    @include border-radius(50px);
    padding: 15px 40px;
    border: 1px solid transparent;
    box-shadow: none;
    &:hover, &:active, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
        background-color: $cwhite;
        border-color: $cprimary;
        color: $cprimary;
        box-shadow: none;
    }
}

header {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
        margin-left: 50px;
    }
    .nav-link {
        color: $cprimary;
        font-weight: 500;
        font-size: 1.3rem;
        text-transform: uppercase;
        position: relative;
        &.current:before, &:hover:before {
            content:'';
            width: calc(100% - 30px);
            height: 1px;
            background-color: $cprimary;
            position: absolute;
            left: 15px;
            bottom: 0px;
            opacity: 1;
        }
    }
    .navbar-toggler {
        font-size: 2.5rem;
        color: $cprimary;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding: 10px 15px;
    }
    .dropdown-menu {
        padding: 0;
        overflow: hidden;
        .dropdown-item {
            font-size: 1.4rem;
            padding: 10px 15px;
        }
    }
}

.home {
    background-image: url('/images/header-home.png');
    background-color: $cwhite;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 100%;
    header {
        margin-bottom: 50px;
        &.sticky {
            background-color: $cwhite;
            position: fixed;
            width: 100%;
            top: 0;
            z-index: 10;
            box-shadow: 0 0 2px rgba(0,0,0,.1), 0 5px 10px rgba(0,0,0,.1);
            .nav-link {
                color: $cprimary;
                &.current:before, &:hover:before {
                    background-color: $cprimary;
                }
            }
            .navbar-toggler {
                color: $cprimary;
            }
        }
        .nav-link {
            color: $cwhite;
            &.current:before, &:hover:before {
                background-color: $cwhite;
            }
        }
        .navbar-toggler {
            color: $cwhite;
        }
    }
    &Banner {
        margin: 50px 0;
        &Title {
            font-size: 5rem;
            line-height: 5.5rem;
            font-weight: 600;
            color: $cblack;
            margin-bottom: 30px;
            span {
                color: $cprimary;
            }
        }
        &Text {
            font-size: 1.8rem;
            margin-bottom: 30px;
        }
        &Cta {
            display: flex;
            align-items: center;
            .btn-primary {
                margin-right: 30px;
            }
            &Link {
                a {
                    font-size: 1.5rem;
                    display: block;
                    &:hover {
                        opacity: 0.8;
                    }
                    &:first-child {
                        color: $cprimary;
                    }
                    &:last-child {
                        color: $ctext;
                    }
                }
            }
        }
    }
    &Cci {
        margin: 50px 0;
        padding: 80px 30px;
        background-color: $cbg;
        &Title {
            color: $cblack;
            font-size: 2.8rem;
            line-height: 3.5rem;
            font-weight: 600;
            margin: 30px 0;
            i {
                color: $cprimary;
            }
        }
        &Text {
            font-size: 1.6rem;
        }
        .videoContainer {
            margin-bottom: 20px;
        }
    }
    &Number {
        margin: 50px 0;
        &Title {
            font-size: 3.5rem;
            color: $cblack;
            margin-bottom: 30px;
        }
        &Value {
            font-size: 5rem;
            font-weight: 700;
            color: $cprimary;
        }
        &Label {
            font-size: 1.8rem;
        }
    }
    &testimonials {
        margin: 50px 0;
        padding: 80px 30px;
        background-color: $cbg;
        &Slider {
            padding: 0 80px;
            text-align: center;
            opacity: 0;
            &.slick-initialized{
                opacity: 1;
            }
            &Text {
                font-size: 1.5rem;
            }
            &Name {
                color: $cprimary;
                font-size: 1.3rem;
                font-weight: 400;
            }
            &Picture {
                margin: 0px auto 20px;
                width: 100px;
                height: 100px;
                background-size: cover;
                background-position: center;
                border: 5px solid $cwhite;
                box-shadow: 6px 7px 10px rgba(0, 0, 0, 0.2);
                @include border-radius(50%);
            }
            .slick-arrow {
                background-color: $cprimary;
                width: 50px;
                height: 50px;
                @include border-radius(50%);
                &:hover, &:focus {
                    background: $cprimary;
                }
                &:before {
                    font-weight: 900;
                    font-family: "Font Awesome 5 Free";
                }
                &.slick-prev {
                    &:before {
                        content: '\f053';
                    }
                }
                &.slick-next {
                    &:before {
                        content: '\f054';
                    }
                }
            }
        }
    }
    &Support {
        margin: 50px 0 0;
        &Title {
            font-size: 3.5rem;
            color: $cblack;
            margin-bottom: 30px;
            &.smaller {
                font-size: 3rem;
            }
        }
        &Logo {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            max-width: 700px;
            margin: 0 auto;
            img {
                margin: 20px;
            }
        }
    }
}

.page {
    label {
        margin-bottom: 10px;
        font-size: 1.5rem;
        &.required:after {
            content: "* ";
        }
    }
    .select2-container .select2-selection--single .select2-selection__arrow b {
        border-width: 5px 5px 0 5px;
        margin-left: -15px;
    }
    .select2-container .select2-dropdown {
        margin-top: -11px;
        border: 1px solid $cborder;
    }
    .select2-container .select2-selection--single, .form-control {
        box-shadow: none;
        border: 1px solid $cborder;
        @include border-radius(30px);
        height: 45px;
        padding: 8px 20px;
        margin-bottom: 10px;
        font-size: 1.4rem;
    }
    textarea.form-control {
        height: 147px;
        @include border-radius(8px);
        resize: none;
        padding-top: 15px;
    }
    .card {
        @include border-radius(0);
        &-body {
            font-size: 1.6rem;
            padding: 20px 40px 50px;
        }
    }
    &Admin {
        h2 {
            color: $cprimary;
            font-size: 2rem;
            font-weight: 600;
        }
        h3 {
            color: $cblack;
            font-size: 1.6rem;
            font-weight: 600;
        }
    }
    &Contact {
        &Title {
            font-size: 2.5rem;
            color: $cblack;
            text-align: center;
            padding: 20px 0;
            margin-bottom: 20px;
            span {
                color: $cprimary;
            }
        }
        &Cat {
            font-size: 2rem;
            font-weight: 500;
            margin-bottom: 20px;
            color: $cprimary;
        }
    }
    &Login {
        background-image: linear-gradient(to right, #01bfd9, #00b3e6, #00a5f2, #0094f9, #017ff7);
        &Logo {
            background-color: $cwhite;
            width: 100%;
            height: 90px;
            text-align: center;
            padding: 15px 0;
            margin-bottom: 50px;
        }
        &Title {
            font-size: 2.5rem;
            color: $cwhite;
        }
        .input-group-alternative {
            @include border-radius(30px);
            overflow: hidden;
            height: 45px;
            .form-control {
                height: 100%;
                font-size: 1.4rem;
            }
            .input-group-text .ni {
                font-size: 1.4rem;
                padding: 0 10px;
            }
        }
    }
    &Faq {
        &Title {
            font-size: 2.5rem;
            color: $cblack;
            font-weight: 700;
            padding: 0 20px;
            &Sub {
                font-size: 2rem;
                color: $cprimary;
                font-weight: 700;
                padding: 0 20px;
            }
        }
        .btn-link {
            font-weight: 500;
            font-size: 1.8rem;
            white-space: normal;
            &:hover {
                color: $cblack;
            }
        }
        .card {
            border-left: 0;
            border-right: 0;
            &-header {
                border-bottom: 0;
                padding: 25px 40px;
            }
            &-body {
                ul, p, h3, h2 {
                    font-size: 1.6rem;
                }
                h3 {
                    color: $cblack;
                }
                ul {
                    padding: 0 0 0 20px;
                    margin-bottom: 30px;
                }
            }
        }
    }
    &Register {
        .nav-tabs {
            border-bottom: 0;
            .nav-item {
                .nav-link {
                    font-size: 2rem;
                    font-weight: 700;
                    color: rgba(0,0,0,0.7);
                    background-color: #f3f3f3;
                    padding: 29px 40px;
                    position: relative;
                    z-index: 2;
                    @include border-radius(0);
                }
                &:first-child .nav-link {
                    margin-right: 30px;
                }
            }
            .nav-link.active, .nav-item.show .nav-link {
                border: 0;
                background-color: $cwhite;
                color: $cblack;
                font-size: 2.5rem;
                padding: 27px 40px;
                box-shadow: 0 -12px 20px 0 rgba(136, 152, 170, 0.15) !important;
            }
        }
        .tab-pane .card {
            position: relative;
            z-index: 1;
            padding: 40px;
        }
    }
    &Header {
        margin-bottom: 40px;
        position: relative;
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(to right top, #017ff7, #008ff8, #009cf4, #00a8ee, #00b3e6, #00b3e6, #00b3e6, #00b3e6, #00a8ee, #009cf4, #008ff8, #017ff7);
        &Title {
            font-size: 5rem;
            line-height: 6rem;
            color: $cwhite;
            text-align: center;
            max-width: 680px;
            span {
                font-size: 1.9rem;
                line-height: 2rem;
                display: block;
            }
        }
        &Visual {
            position: absolute;
            right: 20px;
            bottom: 0;
        }
    }
}

footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 40px;
    background-image: linear-gradient(to right, #01bfd9, #00b3e6, #00a5f2, #0094f9, #017ff7);
    text-align: center;
    color: $cwhite;
    .nav-link {
        font-size: 1.5rem;
        font-weight: 500;
        color: $cwhite;
        padding: 0;
        &:hover {
            color: $cwhite;
            opacity: 0.7;
        }
        span {
            margin: 0 15px;
        }
    }
    .copyright {
        font-size: 1.4rem;
        a {
            color: $cwhite;
        }
    }
}


@media screen and (max-width: 1199px) {
    header .logo {
        margin-left: 35px;
    }
    .home {
        background-size: 118%;
        &Banner {
            &Title {
                font-size: 3.5rem;
                line-height: 4rem;
            }
            &Text {
                padding-right: 90px;
            }
        }
        &CciTitle {
            font-size: 2.4rem;
            line-height: 3.2rem;
        }
    }
    .pageHeader {
        &Visual {
            max-width: 300px;
            right: 10px;
        }
        &Title {
            max-width: 490px;
            font-size: 4rem;
            line-height: 5rem;
        }
    }
    .pageRegister .nav-tabs .nav-item .nav-link {
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media screen and (max-width: 991px) {
    .home {
        background-size: 140%;
        &Banner {
            &Title {
                margin-top: 30px;
            }
            &Text {
                padding-right: 0;
            }
        }
        &Cci .videoContainer {
            margin-top: 30px;
        }
    }
    .pageHeader {
        &Visual {
            display: none;
        }
        &Title {
            max-width: 90%;
            font-size: 4rem;
            line-height: 5rem;
        }
    }
    header {
        .navbar-expand-md .navbar-nav .nav-link {
            padding: 10px;
        }
        .nav-link {
            font-size: 1.2rem;
        }
    }
    .pageRegister {
        .nav-tabs {
            .nav-item {
                &:first-child .nav-link {
                    margin-right: 37px;
                    &.active {
                        margin-right: 62px;
                    }
                }
                .nav-link {
                    padding-left: 20px;
                    padding-right: 20px;
                    &.active {
                        padding: 27px 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .navbar-toggler {
        position: absolute;
        right: 20px;
        top: 25px;
    }
    .navbar-collapse .navbar-toggler {
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .navbar-collapse.collapsing, .navbar-collapse.show {
        margin: 0;
        @include border-radius(0);
        padding: 30px 20px;
    }
    .navbar-collapse {
        width: 100%;
    }
    .navbar {
        position: static;
    }
    .home {
        background-size: 100%;
    }
    header {
        display: block;
    }
    header .nav-link.current:before, header .nav-link:hover:before {
        display: none;
    }

    .home {
        &Number {
            padding: 0 20px;
        }
        &Banner {
            padding: 0 20px;
        }
        &testimonials {
            padding: 50px 30px;
            &Slider {
                padding: 0 10px;
                &Text {
                    font-size: 1.3rem;
                    text-align: left;
                }
                .slick-arrow {
                    &.slick-next {
                        right: -35px;
                    }
                    &.slick-prev {
                        left: -35px;
                    }
                    width: 30px;
                    height: 30px;
                    &:before {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }

    .pageRegister {
        .nav-tabs {
            justify-content: space-between;
            flex-wrap: nowrap;
            align-items: flex-end;
            .nav-item .nav-link {
                padding: 10px 20px;
                font-size: 1.6rem;
                margin-right: 0 !important;
                &.active {
                    padding: 15px 20px;
                }
            }
        }
        .tab-pane .card {
            padding: 30px 20px;
        }
        .registerTabCta {
            display: block !important;
        }
    }
    .page {
        .card-body {
            padding: 20px 30px 50px
        }
        .card-header {
            padding: 25px 30px;
        }
    }

    footer {
        padding: 20px;
        .nav-link {
            font-size: 1.3rem;
            span {
                display: none;
            }
        }
        .copyright {
            font-size: 1.2rem;
        }
        .nav {
            display: block;
        }
    }
}

@media screen and (min-width: 768px) {
    .navbar-expand-md .navbar-nav .nav-link {
        padding: inherit;
    }
}

// tarte au citron
#tarteaucitronRoot {
    #tarteaucitronAlertBig #tarteaucitronPersonalize, #tarteaucitron #tarteaucitronPrivacyUrl {
        background: $cprimary;
    }
    #tarteaucitronPercentage {
        background: $cprimary !important;
    }
    #tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen {
        background-color: $cprimary;
    }
    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow {
        background-color: $cprimary !important;
    }
    #tarteaucitron ul .tarteaucitronLine {
        border-color: $cprimary !important;
    }
    #tarteaucitron #tarteaucitronPrivacyUrl {
        color: $cwhite; 
    }
}
