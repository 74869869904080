.mts { margin-top: 10px; }
.mtm { margin-top: 20px; }
.mtl { margin-top: 30px; }
.mtn { margin-top: 0; }
.mrs { margin-right: 10px; }
.mrm { margin-right: 20px; }
.mrl { margin-right: 30px; }
.mrn { margin-right: 0; }
.mbs { margin-bottom: 10px; }
.mbm { margin-bottom: 20px; }
.mbl { margin-bottom: 30px; }
.mbn { margin-bottom: 0; }
.mls { margin-left: 10px; }
.mlm { margin-left: 20px; }
.mll { margin-left: 30px; }
.mln { margin-left: 0; }
.nm { margin: 0; }
.marging { margin: 20px; }

.pts { padding-top: 10px; }
.ptm { padding-top: 20px; }
.ptl { padding-top: 30px; }
.ptn { padding-top: 0; }
.prs { padding-right: 10px; }
.prm { padding-right: 20px; }
.prl { padding-right: 30px; }
.prn { padding-right: 0; }
.pbs { padding-bottom: 10px; }
.pbm { padding-bottom: 20px; }
.pbl { padding-bottom: 30px; }
.pbn { padding-bottom: 0; }
.pls { padding-left: 10px; }
.plm { padding-left: 20px; }
.pll { padding-left: 30px; }
.pln { padding-left: 0; }
.np { padding: 0; }
.spacing { padding: 20px; }

.tdn { text-decoration: none; }
.tdn:hover { text-decoration: none; }
.tdu { text-decoration: underline; }
.ttu { text-transform: uppercase; }
.dib { display: inline-block; }
.db { display: block; }
.dn { display: none; }
.cb { clear: both; }
.tal { text-align: left; }
.tac { text-align: center; }
.tar { text-align: right; }

.row.ns {
    margin: 0;
    [class*="col-"] {
        padding: 0;
    }
}

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

// Browser Prefixes
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

// Rotate
@mixin rotate ($deg) {
	@include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
	@include transform(scale($scale));
}

// Translate
@mixin translate ($x, $y) {
	@include transform(translate($x, $y));
}

// Skew
@mixin skew ($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin ($origin) {
    	-webkit-transform-origin: $origin;
    	-moz-transform-origin: $origin;
	-ms-transform-origin: $origin;
	transform-origin: $origin;
}

// Animation
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

//placeholder
@mixin placeholder($color, $size:"") {
  &::-webkit-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &::-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-ms-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
}

//text-shadow
@mixin text-shadow($params) {
  -webkit-text-shadow: $params;
     -moz-text-shadow: $params;
          text-shadow: $params;
}

//box-sizing
@mixin border-box() {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

//filter
@mixin filter($type,$amount) {
  -webkit-filter: $type+unquote('(#{$amount})');
  -moz-filter: $type+unquote('(#{$amount})');
  -ms-filter: $type+unquote('(#{$amount})');
  -o-filter: $type+unquote('(#{$amount})');
  filter: $type+unquote('(#{$amount})');
}

// multi columns
@mixin columns($value) {
	-moz-columns: $value;
	-webkit-columns: $value;
	columns: $value;
}

// placeholder color
@mixin placeholder($color, $size:"") {
  &::-webkit-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &::-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-ms-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
}

// width max-content
@mixin max-content() {
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
}


// column-count
@mixin column-count($value) {
    -webkit-column-count: $value;
    -moz-column-count: $value;
    column-count: $value;
}